<script lang="ts" setup>
import {Galleria} from "primevue";

type TProps = {
  images: string[]
}

const props = defineProps<TProps>()
</script>

<template>
  <div class="galleria">
    <Galleria :circular="true" :showItemNavigators="true" :showThumbnails="false" :value="props.images">
      <template #item="slotProps">
        <img :src="slotProps.item"/>
      </template>
    </Galleria>
  </div>
</template>

<style lang="scss">
.galleria {
  @apply relative h-[302px] bg-[#F7F7F7];

  & [data-pc-group-section="itemnavigator"] {
    @apply absolute bg-white bottom-2.5 right-2.5 size-11 flex;

    &[data-pc-section="prevbutton"] {
      @apply right-[70px];
    }

    & svg {
      @apply m-auto size-[14px];
    }
  }

  & [data-pc-name="galleriacontent"],
  & [data-pc-section="content"],
  & [data-pc-section="itemscontainer"],
  & [data-pc-section="items"] {
    @apply h-full;
  }

  & [data-pc-section="item"] {
    @apply flex h-full;

    & img {
      @apply w-2/3 max-w-[80%] max-h-[80%] m-auto;
    }
  }
}
</style>