<script lang="ts" setup>
import {useModalsStore} from "@/stores/modals";

import {Dialog} from "primevue";
import {ref, watch} from "vue";
import {useCartStore} from "@/stores/cart";

const modalStore = useModalsStore()
const cartStore = useCartStore()

const comment = ref('')
const addComment = () => {
  cartStore.addComment({id: modalStore.activeProductId as number, comment: comment.value})
  modalStore.hideCommentModal()
}

watch(() => modalStore.activeProductId, () => {
  if (!modalStore.activeProductId) {
    return
  }

  comment.value = cartStore.getComment(modalStore.activeProductId as number)
})

const isShown = defineModel({
  get() {
    return !!modalStore.activeProductId
  },
  set(value: boolean) {
    if (value) {
      return
    }

    modalStore.activeProductId = null
  }
})
</script>

<template>
  <Dialog v-model:visible="isShown" :dismissable-mask="true" :show-header="false" :style="{ width: '25rem' }" modal pt:mask:class="bg-black/30 transition-opacity cursor-pointer" pt:transition:name="fade-dialog">
    <div class="relative shadow-lg duration-200 bg-[#17172D] text-white font-myriadPro max-desktop:w-[90%] desktop:w-full max-w-[23.813rem] mx-auto flex flex-col items-start p-[1.25rem] rounded-none" data-state="open" role="dialog" style="pointer-events: auto;" tabindex="-1">
      <div class="flex flex-col space-y-1.5 text-center sm:text-left">
        <h2 class="tracking-tight uppercase text-xl max-w-[14.938rem] text-left mb-[1rem] font-semibold">Оставьте комментарий к вашему товару</h2>
      </div>
      <form class="w-full flex flex-col items-stretch mt-[1.2rem] border-none" @submit.prevent="addComment">
        <div class="space-y-2">
          <input v-model="comment" aria-invalid="false" class="w-full flex border-b border-input ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-lg text-black min-h-[4.375rem] placeholder:text-[#1E1E1E] placeholder:text-opacity-20 placeholder:pl-3 placeholder:text-sm p-[0.625rem]" name="comment" type="text">
        </div>
        <button class="inline-flex items-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-[hsl(var(--roke-text-blue))] text-white justify-center mt-[0.625rem] uppercase font-myriadPro w-full mx-auto h-[2rem] text-lg font-semibold rounded-none" type="submit">Сохранить</button>
      </form>
      <p class="text-sm text-muted-foreground"></p>
      <button class="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 outline-none disabled:pointer-events-none" tabindex="-1" type="button" @click="modalStore.hideCommentModal()">
        <svg class="lucide lucide-x h-[1.4rem] w-[1.4rem]" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6 6 18"></path>
          <path d="m6 6 12 12"></path>
        </svg>
      </button>
    </div>
  </Dialog>
</template>