<script lang="ts" setup>
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "primevue";
import {ref, useSlots} from "vue";

type TProps = {
  activeTabClass?: string
  tabsClass?: string
}

const props = defineProps<TProps>()

const slots = useSlots();
const tabsSlot = slots.tabs?.();
const tabsContent = slots.content?.();
const activeTab = ref<number | string>(0)
</script>


<template>
  <Tabs :value="activeTab" @update:value="activeTab = $event">
    <TabList>
      <div :class="props.tabsClass">
        <Tab v-for="(tab, n) in tabsSlot" :key="n" :value="n">
          <component :is="tab" :class="activeTab === n && props.activeTabClass"/>
        </Tab>
      </div>
    </TabList>
    <TabPanels>
      <TabPanel v-for="(tab, n) in tabsContent" :key="n" :value="n">
        <component :is="tab"/>
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>