<script lang="ts" setup>
import {ref, watch} from "vue";
import CatalogMenuItem, {type TItem} from "@/components/CatalogMenuItem.vue";

type TProps = {
  items: TItem[]
}

const props = defineProps<TProps>()

const isShown = ref(false)

const toggleMenu = (): void => {
  isShown.value = !isShown.value
}

const activeItem = ref<null | number>(null)

const keydownHandler = (e: KeyboardEvent): void => {
  if (e.code.toLowerCase() !== 'escape') {
    return
  }

  isShown.value = false
}

const clickHandler = (e: MouseEvent): void => {
  if ((e.target as HTMLElement).closest('.catalog-menu')) {
    return
  }

  isShown.value = false
}

watch(isShown, (): void => {
  if (isShown.value) {
    window.addEventListener('keydown', keydownHandler)
    window.addEventListener('click', clickHandler)

    return
  }

  window.removeEventListener('keydown', keydownHandler)
  window.removeEventListener('click', clickHandler)

  activeItem.value = null
})

const toggleClick = (n: number): void => {
  if (activeItem.value === n) {
    activeItem.value = null

    return
  }

  activeItem.value = n
}
</script>

<template>
  <div>
    <div @click.prevent.stop="toggleMenu">
      <slot></slot>
    </div>
    <div v-if="isShown" class="absolute top-full left-0 bg-white w-[542px] px-5 border catalog-menu">
      <CatalogMenuItem v-for="(item, n) in props.items" :key="item.url" :active="activeItem === n" :item="item" @click="toggleClick(n)"/>
    </div>
  </div>
</template>