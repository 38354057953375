<script lang="ts" setup>
import {useModalsStore} from "@/stores/modals";
import {useCartStore} from "@/stores/cart";

type TProps = {
  productId: number,
  availableCount?: number
}

const modalStore = useModalsStore()
const cartStore = useCartStore()

const props = defineProps<TProps>()
</script>

<template>
  <div class="w-full bg-[hsl(var(--roke-fill-grey))] p-5 flex flex-col gap-5">
    <div class="flex gap-2.5 flex-row">
      <button class="w-full rounded-[3px] h-[58px] desktop:h-[58px] inline-flex items-center whitespace-nowrap ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50 bg-[hsl(var(--roke-text-blue))] text-white justify-center uppercase px-6 font-semibold text-xl leading-[18px]">
        <span class="mt-0.5">В корзину</span>
      </button>
      <button aria-expanded="false" class="size-[58px] bg-white rounded-[3px] flex-shrink-0 flex outline-none" type="button" @click="modalStore.showCommentModal(productId)">
        <div class="relative size-9 m-auto">
            <span class="block size-9 group" data-nimg="1">
              <svg class="opacity-0 group-hover:opacity-100" fill="none" height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.9999 16.5278C35.0066 19.0942 34.407 21.6259 33.2499 23.9167C31.878 26.6617 29.7689 28.9706 27.1589 30.5847C24.5489 32.1988 21.541 33.0544 18.4722 33.0556C15.9058 33.0622 13.3741 32.4626 11.0833 31.3056L0 35L3.69444 23.9167C2.53736 21.6259 1.93775 19.0942 1.94444 16.5278C1.94563 13.459 2.80119 10.4511 4.41528 7.84111C6.02937 5.23109 8.33826 3.12199 11.0833 1.75005C13.3741 0.592976 15.9058 -0.00663592 18.4722 5.53934e-05H19.4444C23.4973 0.22365 27.3253 1.93431 30.1955 4.8045C33.0657 7.67468 34.7763 11.5027 34.9999 15.5556V16.5278Z" fill="#D9D9D9"/>
              </svg>
              <svg class="opacity-100 group-hover:opacity-0 transition-opacity absolute top-0" fill="none" height="37" viewBox="0 0 37 37" width="37" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35.9999 17.5278C36.0066 20.0942 35.407 22.6259 34.2499 24.9167C32.878 27.6617 30.7689 29.9706 28.1589 31.5847C25.5489 33.1988 22.541 34.0544 19.4722 34.0556C16.9058 34.0622 14.3741 33.4626 12.0833 32.3056L1 36L4.69444 24.9167C3.53736 22.6259 2.93775 20.0942 2.94444 17.5278C2.94563 14.459 3.80119 11.4511 5.41528 8.84111C7.02937 6.23109 9.33826 4.12199 12.0833 2.75005C14.3741 1.59298 16.9058 0.993364 19.4722 1.00006H20.4444C24.4973 1.22365 28.3253 2.93431 31.1955 5.8045C34.0657 8.67468 35.7763 12.5027 35.9999 16.5556V17.5278Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
          <div v-if="cartStore.isCommentExists(props.productId)" class="size-3 rounded-full bg-[#3064C7] absolute -bottom-0 -right-0"></div>
            </span>
        </div>
      </button>
      <div class="bg-white h-full flex rounded-[3px]" @click="cartStore.addToFavorite(productId)">
        <div class="text-[#3064C7] size-[58px] bg-white flex cursor-pointer group m-auto rounded-[3px]">
          <div class="m-auto relative size-7 desktop:size-9">
            <svg :class="cartStore.isFavorite(productId) ? 'opacity-0 desktop:group-hover:opacity-100' : 'opacity-100 desktop:group-hover:opacity-0'" class="m-auto transition-opacity absolute top-0 size-7 desktop:size-9" fill="none" height="16" viewBox="0 0 18 16" width="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.63895 2.56888L8.99779 2.93783L9.3562 2.56848L10.0943 1.80786C10.0944 1.80781 10.0944 1.80777 10.0944 1.80773C11.7904 0.064048 14.5299 0.0640894 16.2258 1.80785C17.9253 3.5554 17.9239 6.38663 16.2267 8.14207C16.2265 8.14229 16.2263 8.1425 16.2261 8.14271L9.11721 15.448L9.11711 15.4482C9.04988 15.5173 8.94677 15.5173 8.87954 15.4482L1.77505 8.14311C0.074981 6.39501 0.0749858 3.55523 1.77505 1.80717L1.77505 1.80717C3.46753 0.0668963 6.20567 0.0669012 7.89811 1.80717L7.89812 1.80718L8.63895 2.56888Z" fill="none" stroke="currentColor"/>
            </svg>
            <svg :class="cartStore.isFavorite(productId) ? 'opacity-100 desktop:group-hover:opacity-100' : 'opacity-0 desktop:group-hover:opacity-100'" class="m-auto transition-opacity absolute top-0 size-7 desktop:size-9" fill="none" height="16" viewBox="0 0 18 16" width="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.63895 2.56888L8.99779 2.93783L9.3562 2.56848L10.0943 1.80786C10.0944 1.80781 10.0944 1.80777 10.0944 1.80773C11.7904 0.064048 14.5299 0.0640894 16.2258 1.80785C17.9253 3.5554 17.9239 6.38663 16.2267 8.14207C16.2265 8.14229 16.2263 8.1425 16.2261 8.14271L9.11721 15.448L9.11711 15.4482C9.04988 15.5173 8.94677 15.5173 8.87954 15.4482L1.77505 8.14311C0.074981 6.39501 0.0749858 3.55523 1.77505 1.80717L1.77505 1.80717C3.46753 0.0668963 6.20567 0.0669012 7.89811 1.80717L7.89812 1.80718L8.63895 2.56888Z" fill="currentColor" stroke="currentColor"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="availableCount" class="flex justify-between w-full">
      <div class="flex flex-col gap-2.5">
        <span class="block text-[24px] leading-[24px]">Остатки по складам</span><span class="block text-[hsl(var(--roke-text-grey))] text-[18px] leading-[16.2px]">Центральный склад</span>
      </div>
      <div class="self-end text-[18px] leading-[16.2px]">{{ availableCount }} шт.</div>
    </div>
  </div>
</template>