import {defineStore} from "pinia";
import {ref} from "vue";

export const useModalsStore = defineStore('modals', () => {
    const activeProductId = ref<null | number>(null);

    const showCommentModal = (productId: number): void => {
        activeProductId.value = productId
    }

    const hideCommentModal = (): void => {
        activeProductId.value = null
    }

    return {
        activeProductId,
        hideCommentModal,
        showCommentModal
    }
})