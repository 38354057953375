<script lang="ts" setup>
import {computed, onBeforeUnmount, onMounted, reactive, ref} from "vue";

import Paginator, {type PageState} from 'primevue/paginator';
import ProductRow from "@/components/tables/ProductRow.vue";
import {isDesktop} from "@/helpers/breakpointHelpers";
import MultiSelect from 'primevue/multiselect';
import {Checkbox, Dialog} from "primevue";
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

type TProps = {
  fields: {
    title: string,
    handle: string
  }[],
  products: any[],
  filterEnabled?: boolean
}

const props = withDefaults(defineProps<TProps>(), {
  filterEnabled: false
})

const excludeFieldsFromFilter = ['sku', 'price', 'availableCount', 'section']
const productsPerPage = ref(10)
const page = ref(1)

const displayingFields = computed(() => {
  let fields = Object.values(props.fields)

  fields = fields.sort((a, b) => {
    if (a.handle === 'availableCount' || a.handle !== 'sku') {
      return 1;
    }

    if (b.handle === 'availableCount' || b.handle !== 'sku') {
      return -1;
    }

    return 0;
  })

  return fields.filter((field) => {
    if (field.handle === 'price') {
      return
    }

    return props.products.some((product) => {
      return typeof product[field.handle] !== 'undefined' && product[field.handle]
    })
  })
})

const filters = reactive(new Map([]))

const availableFiltersField = computed(() => {
  return displayingFields.value.filter((field) => {
    return !excludeFieldsFromFilter.includes(field.handle)
  })
})

const changePageHandler = (pageEvent: PageState) => {
  page.value = pageEvent.page + 1
}

const rowsPerPageUpdateHandler = (rowsPerPage: number) => {
  productsPerPage.value = rowsPerPage
}

const fieldOptions = (handle: string) => {
  if (excludeFieldsFromFilter.includes(handle)) {
    return
  }

  const values = new Map()

  props.products.forEach((product) => {
    if (product[handle]) {
      values.set(product[handle], product[handle])
    }
  })

  return Array.from(values)
}

const clearFilters = () => {
  filters.clear()
}

const displayingProducts = computed(() => {
  let _products = props.products

  if (filters.size > 0) {
    _products = _products.filter((product) => {
      let isDisplaying = true

      filters.forEach((value, filterHandle) => {
        const values = value as string[]

        if (values.length === 0) {
          return;
        }

        if (!filters.has(filterHandle)) {
          return
        }

        const isFieldShown = typeof product[filterHandle as string] !== 'undefined' && values.includes(product[filterHandle as string])

        if (!isFieldShown) {
          isDisplaying = false
        }
      })

      return isDisplaying
    })
  }

  return _products.slice((page.value - 1) * productsPerPage.value, page.value * productsPerPage.value)
})

const isMobile = ref(!isDesktop())

const resizeHandler = (): void => {
  isMobile.value = !isDesktop()
}

onMounted(() => {
  window.addEventListener('resize', resizeHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
})

const isShownFiltersModal = ref(false)

const showFiltersModal = () => isShownFiltersModal.value = true
const closeFiltersModal = () => isShownFiltersModal.value = false
</script>

<template>
  <div v-if="props.filterEnabled" class="w-full bg-[#17172D] text-[hsl(var(--roke-white))] p-2.5 desktop:p-5">
    <Dialog v-model:visible="isShownFiltersModal" :auto-z-index="false" :show-header="false" block-scroll class="p-2.5 bg-white w-full h-screen pt-[100px]" pt:content:class="h-full" pt:transition:name="fade-dialog">
      <div class="border p-4 h-full flex flex-col">
        <div class="flex flex-row justify-between items-center pb-5">
          <span class="font-myriadPro text-[30px] leading-[27px] mt-0.5">Фильтры</span>
          <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg" @click="closeFiltersModal">
            <path d="M0.170768 0.352789L0.255101 0.255101C0.564318 -0.0541159 1.04818 -0.082219 1.38915 0.170768L1.48684 0.255101L9.00001 7.76788L16.5132 0.255101C16.8534 -0.0850295 17.4047 -0.0850295 17.7449 0.255101C18.085 0.595232 18.085 1.14671 17.7449 1.48684L10.2321 9.00001L17.7449 16.5132C18.0541 16.8223 18.0822 17.3063 17.8292 17.6472L17.7449 17.7449C17.4357 18.0541 16.9518 18.0822 16.6109 17.8292L16.5132 17.7449L9.00001 10.2321L1.48684 17.7449C1.14671 18.085 0.595232 18.085 0.255101 17.7449C-0.0850295 17.4047 -0.0850295 16.8534 0.255101 16.5132L7.76788 9.00001L0.255101 1.48684C-0.0541159 1.17762 -0.082219 0.693756 0.170768 0.352789Z" fill="#212121"/>
          </svg>
        </div>
        <div>
          <Accordion :value="0">
            <AccordionPanel v-for="(field, n) in availableFiltersField" :key="field.handle" :value="n" class="border-t last-of-type:border-b">
              <AccordionHeader class="flex flex-row items-center justify-between w-full h-[62px] font-myriadPro text-[24px] leading-[21.6px]">{{ field.title }}</AccordionHeader>
              <AccordionContent class="pb-5">
                <div v-for="option of fieldOptions(field.handle)" :key="option[0]" class="flex items-center gap-2">
                  <Checkbox :input-id="option[1]" :model-value="filters.get(field.handle)" :name="field.handle" :value="option[1]" class="checkbox" pt:input:class="hidden" @update:model-value="filters.set(field.handle, $event)"/>
                  <label :for="option[1]">{{ option[0] }}</label>
                </div>
              </AccordionContent>
            </AccordionPanel>
          </Accordion>
        </div>
        <div class="mt-auto flex flex-col gap-2.5">
          <button class="h-11 flex bg-[hsl(var(--roke-text-blue))]" type="button" @click="closeFiltersModal">
            <span class="uppercase m-auto text-white font-myriadPro font-semibold text-[16px] leading-[16px]">Применить</span>
          </button>
          <button class="h-11 flex border" type="button" @click="clearFilters">
            <span class="uppercase m-auto font-myriadPro font-semibold text-[16px] leading-[16px]">Очистить</span>
          </button>
        </div>
      </div>
    </Dialog>
    <template v-if="!isMobile">
      <span class="font-semibold text-[38px] leading-[38px]">ПОДБОР ПО ПАРАМЕТРАМ</span>
      <div class="mt-5 flex gap-5">
        <MultiSelect v-for="field in availableFiltersField" :model-value="filters.get(field.handle)" :options="fieldOptions(field.handle)" :placeholder="field.title" :show-toggle-all="false" class="select" empty-filter-message="Ничего не найдено" filter option-label="0" option-value="1" pt:overlay:class="multiselect" @update:model-value="filters.set(field.handle, $event)"/>
        <button class="inline-flex items-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-[hsl(var(--roke-text))] font-myriadPro font-semibold hover:text-white hover:bg-[hsl(var(--roke-text-blue))] justify-center px-5" @click="clearFilters">Сбросить</button>
      </div>
    </template>
    <template v-else>
      <button class="bg-[#3064C7] w-full h-[30px] flex" type="button" @click="showFiltersModal">
        <span class="text-white m-auto text-[14px] leading-[12px] flex flex-row gap-2 pt-0.5">
          Фильтры
          <svg fill="none" height="10" viewBox="0 0 13 10" width="13" xmlns="http://www.w3.org/2000/svg">
<path d="M1.24957 8.38785C1.66355 8.38785 1.99915 8.74873 1.99915 9.19393C1.99915 9.63913 1.66355 10 1.24957 10C0.835598 10 0.5 9.63913 0.5 9.19393C0.5 8.74873 0.835598 8.38785 1.24957 8.38785ZM3.35 8.71046H12.05C12.2985 8.71046 12.5 8.92713 12.5 9.19438C12.5 9.43937 12.3307 9.64184 12.1111 9.67391L12.05 9.67829H3.35C3.10147 9.67829 2.9 9.46163 2.9 9.19438C2.9 8.94939 3.06929 8.74692 3.28894 8.71485L3.35 8.71046ZM1.24957 4.19393C1.66355 4.19393 1.99915 4.5548 1.99915 5C1.99915 5.4452 1.66355 5.80607 1.24957 5.80607C0.835598 5.80607 0.5 5.4452 0.5 5C0.5 4.5548 0.835598 4.19393 1.24957 4.19393ZM3.35 4.51654H12.05C12.2985 4.51654 12.5 4.7332 12.5 5.00045C12.5 5.24544 12.3307 5.44791 12.1111 5.47998L12.05 5.48437H3.35C3.10147 5.48437 2.9 5.2677 2.9 5.00045C2.9 4.75546 3.06929 4.55299 3.28894 4.52092L3.35 4.51654ZM1.24957 0C1.66355 0 1.99915 0.360891 1.99915 0.806066C1.99915 1.25124 1.66355 1.61213 1.24957 1.61213C0.835598 1.61213 0.5 1.25124 0.5 0.806066C0.5 0.360891 0.835598 0 1.24957 0ZM3.35 0.32261H12.05C12.2985 0.32261 12.5 0.539268 12.5 0.806524C12.5 1.05151 12.3307 1.25398 12.1111 1.28602L12.05 1.29044H3.35C3.10147 1.29044 2.9 1.07378 2.9 0.806524C2.9 0.561535 3.06929 0.359071 3.28894 0.327029L3.35 0.32261Z" fill="white"/>
</svg>
        </span>
      </button>
    </template>
  </div>
  <div class="w-full">
    <table class="table-auto border-collapse desktop:border text-xl leading-[16.2px] mb-[1.875rem] w-full">
      <thead class="uppercase max-desktop:hidden">
      <tr>
        <th v-for="field in displayingFields" :key="field.title" class="border p-5">{{ field.title }}</th>
        <th class="border p-5">Цена</th>
        <th class="border p-5">Комментарий</th>
        <th class="border p-5">Количество</th>
        <th class="border p-5">Купить</th>
      </tr>
      </thead>
      <tbody class="max-desktop:flex flex-col gap-2.5 text-center text-lg">
      <ProductRow v-for="product in displayingProducts" :key="product.id" :fields="displayingFields" :product="product"/>
      </tbody>
    </table>
    <nav aria-label="pagination" class="mx-auto flex w-full justify-center mb-5 desktop:mb-[5rem]" role="navigation">
      <Paginator :rows="productsPerPage" :rowsPerPageOptions="[10, 20, 30]" :totalRecords="props.products.length" class="paginator" pt:content="flex flex-row items-center" @page="changePageHandler" @update:rows="rowsPerPageUpdateHandler"></Paginator>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.select {
  @apply w-96 outline-none flex flex-row items-center bg-white p-2.5 text-[#808080] font-myriadPro text-[18px] leading-[16.2px] cursor-pointer justify-between;

  &:deep([data-pc-section="overlay"]) {
    @apply bg-white;
  }

  &:deep([data-pc-section="dropdown"]) {
    @apply text-black;
  }

  &:deep([data-pc-section="label"]) {
    @apply outline-none mt-0.5;
  }
}

.paginator {
  @apply flex flex-row;

  &:deep([data-pc-section="pages"]) {
    @apply flex flex-row gap-2 mt-0.5;
  }

  &:deep([data-pc-section="page"]) {
    @apply opacity-30 transition-opacity;

    &[data-p-active="true"],
    &:hover {
      @apply opacity-100;
    }
  }

  &:deep([data-pc-group-section="pagebutton"]:disabled) {
    @apply opacity-30;
  }

  &:deep([data-pc-section="content"]) {
    @apply flex flex-row gap-2;
  }

  &:deep([data-pc-group-section="pagedropdown"]) {
    @apply flex flex-row ml-10 p-2 border rounded-sm items-center gap-2 cursor-pointer;
  }
}
</style>

<style lang="scss">
.multiselect {
  @apply bg-white border;

  & [data-pc-section="header"] {
    @apply flex items-center gap-2.5 p-2.5 border-b;
  }

  & [data-pc-name="pcfiltercontainer"] {
    @apply flex items-center border py-1 px-2.5 outline-none w-full gap-2.5;
  }

  & input {
    @apply outline-none w-full;
  }

  & [role="option"] {
    @apply flex items-center gap-2.5 text-black !opacity-100 cursor-pointer;
  }

  & [data-pc-section="optionlabel"] {
    @apply mt-0.5;
  }

  & [data-pc-section="input"] {
    @apply hidden;
  }
}

.multiselect,
.checkbox {
  & [data-pc-section="box"] {
    @apply size-3.5 border border-black;

    &:not(:empty) {
      @apply bg-[hsl(var(--roke-text-blue))];
    }

    & > svg {
      @apply hidden;
    }
  }
}

[data-pc-section="list"] {
  @apply flex flex-col gap-1 p-2 font-myriadPro bg-white;

  & > [role="option"] {
    @apply opacity-30 cursor-pointer;

    &[data-p-selected="true"],
    &:hover {
      @apply opacity-100;
    }
  }
}
</style>