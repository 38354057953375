<script lang="ts" setup>
import {ref} from "vue";

export type TItem = {
  url: string,
  title: string,
  image: string,
  children: TItem[]
}

type TProps = {
  item: TItem,
  active?: boolean,
  isLink?: boolean
}

const emit = defineEmits([
  'click'
])
const props = withDefaults(defineProps<TProps>(), {
  active: false,
  isLink: false
})

const isShown = ref(props.active)
const activeItem = ref<null | number>(null)

const toggleMenu = (e: MouseEvent): void => {
  if (props.isLink) {
    return
  }

  e.preventDefault()

  isShown.value = !isShown.value

  emit('click')
}
</script>

<template>
  <div>
    <div @click="toggleMenu">
      <a :class="{'text-white bg-[#3064C7]': props.active}" :href="item.url" class="border-b relative transition-colors cursor-pointer flex select-none h-[70px] items-center hover:text-white hover:bg-[#3064C7] p-2.5" data-orientation="vertical" data-radix-collection-item="" role="menuitem" tabindex="-1">
        <img :src="props.item.image" alt="Соединитель" data-nimg="1" decoding="async" height="48" loading="lazy" width="60"><span class="ml-[1.563rem] font-myriadPro font-semibold text-lg">
      {{ item.title }}</span>
      </a>
    </div>
    <div v-if="props.active" class="absolute top-0 left-full h-full bg-white w-[542px] px-5 border-x">
      <CatalogMenuItem v-for="(item, n) in props.item.children" :key="item.url" :active="activeItem === n" :is-link="true" :item="item" @click="activeItem = n"/>
    </div>
  </div>
</template>