import {defineStore} from "pinia";
import {computed, ref} from "vue";

export type TCartItem = {
    id: number,
    title: string,
    count: number,
    price: number,
    amount: number
}

export type TComment = {
    id: number,
    comment: string
}

export const useCartStore = defineStore('cart', () => {
    const items = ref<Map<string | number, TCartItem>>(new Map([]))
    const _favorites = ref<string[]>([])
    const _comments = ref({})

    const comments = computed<Map<string, string>>(() => {
        return new Map(Object.entries(_comments.value))
    })

    const favorites = computed<Set<string>>(() => {
        return new Set(_favorites.value);
    })

    const addToCart = (item: TCartItem) => items.value
    const addToFavorite = (productId: number) => {
        if (favorites.value.has(productId.toString())) {
            favorites.value.delete(productId.toString())

            _favorites.value = Array.from(favorites.value.values())

            return
        }
        
        favorites.value.add(productId.toString())

        _favorites.value = Array.from(favorites.value.values())

        return
    }
    const addComment = (_comment: TComment) => {
        const {id, comment} = _comment

        if (comment) {
            comments.value.set(id.toString(), comment)

            _comments.value = Object.fromEntries(comments.value)

            return
        }

        comments.value.delete(id.toString())

        _comments.value = Object.fromEntries(comments.value)
    }

    const isCommentExists = (productId: number) => {
        return comments.value.has(productId.toString())
    }
    const isFavorite = (productId: number) => {
        return favorites.value.has(productId.toString())
    }
    const getComment = (productId: number): string => {
        return comments.value.get(productId.toString()) ?? ''
    }

    return {
        items, favorites, addToCart, addComment, isCommentExists, comments, _comments, getComment, addToFavorite, isFavorite, _favorites
    }
}, {
    persist: true
})