<script lang="ts" setup>
import {onBeforeUnmount, onMounted, ref} from "vue";
import {isDesktop} from "@/helpers/breakpointHelpers";

type TTab = {
  id: number,
  title: string,
  text: string,
  url: string
}

type TProps = {
  tabs: TTab[]
  activeTab: TTab
}

const props = defineProps<TProps>()

const activeTab = ref<TTab>(props.activeTab)

const selectTab = (tab: TTab): void => {
  activeTab.value = tab
  document.title = activeTab.value.title
  window.history.pushState({}, '', activeTab.value.url)

  return
}

const isMobile = ref(!isDesktop())

const isActive = (tab: TTab): boolean => tab.id === activeTab.value.id

const resizeHandler = (): void => {
  isMobile.value = !isDesktop()
}

onMounted(() => {
  window.addEventListener('resize', resizeHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler)
})
</script>

<template>
  <div class="col-span-2 border border-[#D9D9D9] flex flex-col items-stretch text-xl">
    <a v-for="tab in props.tabs" :key="tab.id" :href="tab.url" :title="tab.title" @click.prevent="selectTab(tab)">
      <span :class="{'desktop:bg-[#3064C7] max-desktop:!text-[#3064C7] desktop:text-white border-none': isActive(tab)}" class="transition-colors desktop:hover:bg-[#3064C7] hover:text-white h-[76px] text-[20px] leading-[18px] font-myriadPro border-b p-5 border-[#D9D9D9] flex justify-between items-center">
        <span class="uppercase font-semibold">{{ tab.title }}</span>
        <svg class="hidden desktop:block" fill="#ffffff" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="4.5" stroke="#D9D9D9"></circle>
        </svg>
        <svg :class="{'rotate-180': isActive(tab)}" class="desktop:hidden transition-transform" fill="none" height="10" viewBox="0 0 16 10" width="16" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 9L8 2L1 9" stroke="currentColor" stroke-width="2"/>
        </svg>
      </span>
      <span v-if="isMobile && isActive(tab)" class="border-b border-[#D9D9D9] p-5 pt-0 text-content" v-html="activeTab.text"></span>
    </a>
  </div>
  <template v-if="!isMobile">
    <Transition mode="out-in" name="tabs">
      <div :key="activeTab.id" class="col-span-4 border p-[30px] flex flex-col gap-[60px]">
        <h2 class="font-bold uppercase text-[70px] leading-[63px] font-centuryGothic">{{ activeTab.title }}</h2>
        <div class="items-start text-content" v-html="activeTab.text"></div>
      </div>
    </Transition>
  </template>
</template>