import './assets/index.scss'

import {createApp} from 'vue'
import {createPinia} from 'pinia'

import PrimeVue from 'primevue/config'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import AccordionComponent from "@/components/AccordionComponent.vue";
import TabsComponent from "@/components/TabsComponent.vue";
import CatalogMenu from "@/components/CatalogMenu.vue";
import ProductsTable from "@/components/tables/ProductsTable.vue";
import CommentModal from "@/components/modals/CommentModal.vue";
import CartSection from "@/components/products/CartSection.vue";
import ImageGallery from "@/components/products/ImageGallery.vue";

const app = createApp({
    components: {
        AccordionComponent,
        CartSection,
        CatalogMenu,
        CommentModal,
        ImageGallery,
        ProductsTable,
        TabsComponent,
    },
})
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(PrimeVue, {unstyled: true})

app.mount('#app')
