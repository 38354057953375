<script lang="ts" setup>
import {useModalsStore} from "@/stores/modals";
import {useCartStore} from "@/stores/cart";

type TProps = {
  product: any,
  fields: {
    title: string,
    handle: string
  }[],
}

const modalStore = useModalsStore()
const cartStore = useCartStore()

const props = defineProps<TProps>()
</script>

<template>
  <tr :key="props.product.sku" class="max-desktop:flex flex-col items-start max-desktop:border max-desktop:bg-[#F7F7F7]">
    <td v-for="field in props.fields" :key="field.title" :class="field.handle === 'sku' ? '' : 'max-desktop:hidden'" class="desktop:border p-2">
      <component :is="field.handle === 'sku' ? 'a' : 'span'" :class="field.handle === 'sku' ? 'transition-colors hover:text-[hsl(var(--roke-text-blue))]' : ''" :href="props.product.url" class="max-desktop:text-left">
        <span class="max-desktop:block max-desktop:text-[16px] max-desktop:leading-[16px]">
          <span v-if="field.handle === 'sku'" class="desktop:hidden">Артикул: </span>
          <span class="max-desktop:font-semibold">{{ props.product[field.handle] }}</span>
        </span>
        <h2 class="desktop:hidden mt-4 !font-myriadPro text-[24px] leading-[24px]">{{ props.product.title }}</h2>
      </component>
    </td>
    <td class="desktop:border p-2 max-desktop:mt-4 max-desktop:text-[16px] max-desktop:leading-[16px]">
      <span>
          <span class="desktop:hidden">Цена: </span>
          <span class="max-desktop:font-semibold">{{ props.product.price }}</span>
        </span>
    </td>
    <td class="desktop:border p-2 max-desktop:hidden">
      <button aria-expanded="false" aria-haspopup="dialog" class="outline-none" data-state="closed" type="button" @click="modalStore.showCommentModal(props.product.id)">
        <div class="relative">
          <span class="block size-8 group" data-nimg="1">
            <img alt="comment" class="transition-opacity bg-clip-content opacity-100 group-hover:opacity-0" data-nimg="1" decoding="async" height="32" loading="lazy" src="/icons/comment.svg" width="32">
            <img alt="comment" class="transition-opacity bg-clip-content absolute top-0 opacity-0 group-hover:opacity-100" data-nimg="1" decoding="async" height="32" loading="lazy" src="/icons/comment_hover.svg" width="32">
          </span>
          <div v-if="cartStore.isCommentExists(props.product.id)" class="size-4 rounded-full bg-[#3064C7] absolute -bottom-0.5 -right-0.5"></div>
        </div>
      </button>
    </td>
    <td class="max-desktop:hidden desktop:border p-2">
      <div class="flex justify-center items-center font-myriadPro">
        <div class="border min-w-[100px] h-9 flex flex-row items-center justify-around">
          <button class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground">
            <svg class="size-3.5" fill="currentColor" height="1em" stroke="currentColor" stroke-width="0" viewBox="0 0 448 512" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"></path>
            </svg>
          </button>
          <span class="font-semibold text-xl leading-[90%] mt-0.5">1</span>
          <button class="inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground">
            <svg class="size-3.5" fill="currentColor" height="1em" stroke="currentColor" stroke-width="0" viewBox="0 0 448 512" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"></path>
            </svg>
          </button>
        </div>
      </div>
    </td>
    <td class="desktop:border p-2 max-desktop:flex max-desktop:w-full">
      <div class="h-full w-full flex flex-row justify-center gap-2.5 items-center">
        <button class="max-desktop:w-full rounded-[3px] h-11 desktop:h-9 inline-flex items-center whitespace-nowrap ring-offset-background transition-colors disabled:pointer-events-none disabled:opacity-50 bg-[hsl(var(--roke-text-blue))] text-white justify-center uppercase px-6 font-semibold text-xl leading-[16.2px]">
          <span class="mt-0.5">В корзину</span>
        </button>
        <button aria-expanded="false" class="size-11 bg-white rounded-[3px] flex-shrink-0 flex outline-none desktop:hidden" type="button" @click="modalStore.showCommentModal(props.product.id)">
          <div class="relative size-7 m-auto">
            <span class="block size-7 group" data-nimg="1">
              <img alt="comment" class="transition-opacity bg-clip-content" data-nimg="1" decoding="async" height="32" loading="lazy" src="/icons/comment_hover.svg" width="32">
            </span>
            <div v-if="cartStore.isCommentExists(props.product.id)" class="size-3 rounded-full bg-[#3064C7] absolute -bottom-0.5 -right-0.5"></div>
          </div>
        </button>
        <div class="bg-white h-full flex" @click="cartStore.addToFavorite(product.id)">
          <div class="text-[#3064C7] size-11 desktop:size-9 desktop:bg-[#F7F7F7] flex cursor-pointer group m-auto rounded-[3px]">
            <div class="m-auto relative size-7 desktop:size-4">
              <svg :class="cartStore.isFavorite(product.id) ? 'opacity-0 desktop:group-hover:opacity-100' : 'opacity-100 desktop:group-hover:opacity-0'" class="m-auto transition-opacity absolute top-0 size-7 desktop:size-4" fill="none" height="16" viewBox="0 0 18 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.63895 2.56888L8.99779 2.93783L9.3562 2.56848L10.0943 1.80786C10.0944 1.80781 10.0944 1.80777 10.0944 1.80773C11.7904 0.064048 14.5299 0.0640894 16.2258 1.80785C17.9253 3.5554 17.9239 6.38663 16.2267 8.14207C16.2265 8.14229 16.2263 8.1425 16.2261 8.14271L9.11721 15.448L9.11711 15.4482C9.04988 15.5173 8.94677 15.5173 8.87954 15.4482L1.77505 8.14311C0.074981 6.39501 0.0749858 3.55523 1.77505 1.80717L1.77505 1.80717C3.46753 0.0668963 6.20567 0.0669012 7.89811 1.80717L7.89812 1.80718L8.63895 2.56888Z" fill="none" stroke="currentColor"/>
              </svg>
              <svg :class="cartStore.isFavorite(product.id) ? 'opacity-100 desktop:group-hover:opacity-100' : 'opacity-0 desktop:group-hover:opacity-100'" class="m-auto transition-opacity absolute top-0 size-7 desktop:size-4" fill="none" height="16" viewBox="0 0 18 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.63895 2.56888L8.99779 2.93783L9.3562 2.56848L10.0943 1.80786C10.0944 1.80781 10.0944 1.80777 10.0944 1.80773C11.7904 0.064048 14.5299 0.0640894 16.2258 1.80785C17.9253 3.5554 17.9239 6.38663 16.2267 8.14207C16.2265 8.14229 16.2263 8.1425 16.2261 8.14271L9.11721 15.448L9.11711 15.4482C9.04988 15.5173 8.94677 15.5173 8.87954 15.4482L1.77505 8.14311C0.074981 6.39501 0.0749858 3.55523 1.77505 1.80717L1.77505 1.80717C3.46753 0.0668963 6.20567 0.0669012 7.89811 1.80717L7.89812 1.80718L8.63895 2.56888Z" fill="currentColor" stroke="currentColor"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>